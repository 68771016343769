(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .controller('ConnectionAliasDetailController', ConnectionAliasDetailController);

    ConnectionAliasDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ConnectionAlias', 'Rule'];

    function ConnectionAliasDetailController(
        $scope, $rootScope, $stateParams, previousState, entity,
        ConnectionAlias, Rule
    ) {
        var vm = this;

        vm.connectionAlias = entity;
        Rule.get(function (result) {
            vm.rulesList = result;
        }, function (error) {
            vm.rulesList = null;
        });

        vm.getRuleName = function (ruleId) {
            if (!vm.rulesList) {
                return "Not Loaded"
            }
            for (var i = 0; i < vm.rulesList.length; i++) {
                const rule = vm.rulesList[i];
                if (rule.id === ruleId) {
                    return rule.name;
                }
            }
            return "Not found (ID: " + ruleId + ")";
        }


        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('admintoolApp:connectionAliasUpdate', function(event, result) {
            vm.connectionAlias = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
