(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .factory('ConnectionAliasFilter', ConnectionAliasFilter);

    ConnectionAliasFilter.$inject = ['$resource'];


    function ConnectionAliasFilter($resource, name, connectionType, protocol, host, port, path, authentication, emailRecipients) {
        var resourceUrl = 'api/_filter/connectionAlias';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                params: {
                    name: name, connectionType: connectionType, protocol: protocol, host: host, port: port, path: path,
                    authentication: authentication, emailRecipients: emailRecipients
                },
                isArray: true
            }
        });
    }
})();
