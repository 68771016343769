(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .factory('MessageDataTableColumns', MessageDataTableColumns);

    MessageDataTableColumns.$inject = ['dataTableColumnConfigHelper'];

    function MessageDataTableColumns (dataTableColumnConfigHelper) {

        const columnDefinition = {

            getDefinition: function() {

                return [
                    // TODO: fix type directive first
                    // {
                    //     name: 'received',
                    //     translationId: 'admintoolApp.message.received',
                    //     type: 'DATE'
                    // },
                    {
                        name: 'messageId',
                        translationId: 'admintoolApp.message.messageId',
                        type: 'HTML',
                        enableFiltering: true,
                        filters: dataTableColumnConfigHelper.getHtmlFilter('messageId'),
                        filterHeaderTemplate: dataTableColumnConfigHelper.getFilterHeaderTemplate(),
                        template: '<a ui-sref="message-detail({id:row.entity.id})">{{row.entity.messageId}}</a>'
                    },
                    {
                        name: 'status',
                        translationId: 'admintoolApp.message.status',
                        type: 'HTML',
                        enableFiltering: true,
                        filters: dataTableColumnConfigHelper.getHtmlFilter('status'),
                        filterHeaderTemplate: dataTableColumnConfigHelper.getFilterHeaderTemplate(),
                        template: '<span translate="{{\'admintoolApp.MessageStatus.\' + row.entity.status}}">{{row.entity.status}}</span>'
                    },
                    {
                        name: 'type',
                        translationId: 'admintoolApp.message.type',
                        type: 'HTML',
                        enableFiltering: true,
                        filters: dataTableColumnConfigHelper.getHtmlFilter('type'),
                        filterHeaderTemplate: dataTableColumnConfigHelper.getFilterHeaderTemplate(),
                        template: '<span translate="{{\'admintoolApp.MessageType.\' + row.entity.type}}">{{row.entity.type}}></span>'
                    },
                    {
                        name: 'referenceNumber',
                        translationId: 'admintoolApp.message.referenceNumber',
                        type: 'TEXT'
                    },
                    // TODO: fix type directive first
                    // {
                    //     name: 'delivered',
                    //     translationId: 'admintoolApp.message.delivered',
                    //     type: 'DATE'
                    // },
                    {
                        name: 'senderName',
                        translationId: 'admintoolApp.message.sender',
                        type: 'HTML',
                        enableFiltering: true,
                        filters: dataTableColumnConfigHelper.getHtmlFilter('senderName'),
                        filterHeaderTemplate: dataTableColumnConfigHelper.getFilterHeaderTemplate(),
                        template: ' <a ui-sref="partner-detail({id:row.entity.senderId})">{{row.entity.senderName}}</a>'
                    },{
                        name: 'receiverName',
                        translationId: 'admintoolApp.message.receiver',
                        type: 'HTML',
                        enableFiltering: true,
                        filters: dataTableColumnConfigHelper.getHtmlFilter('receiverName'),
                        filterHeaderTemplate: dataTableColumnConfigHelper.getFilterHeaderTemplate(),
                        template: '<a ui-sref="partner-detail({id:row.entity.receiverId})">{{row.entity.receiverName}}</a>'
                    },
                    {
                        name: 'actions',
                        translationId: 'entity.action.actions',
                        type: 'HTML',
                        width: 130,
                        template: '<div class="btn-group flex-btn-group-container">' +
                            '<button type="submit" title="{{ \'entity.action.delete\' | translate }}" ui-sref="message.reprocess({id:row.entity.id})" class="btn btn-warning btn-sm" ng-show="row.entity.status === \'ERROR\'">' +
                                '<span class="glyphicon glyphicon-play"></span>' +
                            '</button>' +
                            '<button type="submit" title="{{ \'entity.action.edit\' | translate }}" ui-sref="message.resend({id:row.entity.id})" class="btn btn-danger btn-sm" ng-show="row.entity.status === \'SENT\'">' +
                                '<span class="glyphicon glyphicon-fast-backward"></span>' +
                            '</button>' +
                            '<button type="submit" title="{{ \'entity.action.view\' | translate }}" ui-sref="message.edit({id:row.entity.id})" class="btn btn-primary btn-sm" has-authority="ROLE_ADMIN" ng-show="row.entity.status !== \'FINISHED\'">' +
                                '<span class="glyphicon glyphicon-pencil"></span> ' +
                            '</button>' +
                        '</div>'
                    }
                ];
            }
        };

        return columnDefinition;
    }
})();
