(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .factory('MessageFilter', MessageFilter);

    MessageFilter.$inject = ['$resource'];


    function MessageFilter($resource, receiver, messageId, referenceNumber, sender, type, status, received, range) {
        var resourceUrl = 'api/_filter/messages';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                params: {receiver: receiver, messageId: messageId, referenceNumber: referenceNumber,
                    sender: sender, type: type, status: status,
                    received: received, range: range},
                isArray: true
            }
        });
    }
})();
