(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .controller('PartnerController', PartnerController)
        .filter('startFrom', function() {
            return function(input, start) {
                start = +start; //parse to int
                return input.slice(start);
            }
        });

    PartnerController.$inject = ['$scope', '$state', 'Partner', 'ParseLinks', 'AlertService', 'PartnerFilter'];

    function PartnerController($scope, $state, Partner, ParseLinks, AlertService, PartnerFilter) {
        var vm = this;

        vm.partners = [];
        vm.currentPage = 0;
        vm.pageSize = 20;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'name';
        vm.reset = reset;
        vm.reverse = true;
        vm.clear = clear;
        vm.loadWithFilter = loadWithFilter;
        var activeFilter = false;

        loadAll(vm.currentPage);

        function loadAll(page) {
            if (activeFilter) {
                PartnerFilter.query({
                    name: $scope.vm.partner.name,
                    designation: $scope.vm.partner.designation,
                    status: $scope.vm.partner.status,
                    community: $scope.vm.partner.community,
                    alias: $scope.vm.partner.alias,
                    page: page - 1,
                    size: vm.pageSize,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Partner.query({
                    page: page - 1,
                    size: vm.pageSize,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'name') {
                    result.push('name');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.partners.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.partners = [];
            loadAll(vm.page);
        }

        function clear () {
            vm.partners = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'name';
            vm.reverse = true;
            vm.loadAll(vm.page);
        }

        $scope.changePage = function () {
            vm.partners = [];
            loadAll(vm.currentPage);
        };

        function loadWithFilter() {
            activeFilter = true;
            vm.partners = [];
            vm.links = null;
            vm.predicate = 'name';
            vm.page = 0;
            vm.reverse = true;
            loadAll(vm.page)
        }
    }
})();
