(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .controller('MessageReprocessController',MessageReprocessController);

    MessageReprocessController.$inject = ['$uibModalInstance', 'entity', 'Message'];

    function MessageReprocessController($uibModalInstance, entity, Message) {
        var vm = this;

        vm.message = entity;
        vm.clear = clear;
        vm.confirmResend = confirmResend;
        vm.confirmReprocess = confirmReprocess;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function confirmResend (id) {
            Message.resend({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
        
        function confirmReprocess (id) {
            Message.reprocess({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
