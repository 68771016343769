(function () {
    'use strict';
    angular
        .module('admintoolApp')
        .controller('FileUploadController', FileUploadController);
    FileUploadController.$inject = ['$scope', 'FileUploadService'];

    function FileUploadController($scope, FileUploadService) {
        $scope.uploadFile = function () {
            $scope.color = 'white';
            var file = $scope.myFile,
                promise = FileUploadService.uploadFileToUrl(file);

            promise.then(function (response) {
                $scope.serverResponse = response;
                if (response.message === 'File was uploaded successfully!') {
                    $scope.color = 'darkseagreen';
                } else {
                    $scope.color = 'lightcoral';
                }
            }, function () {
                $scope.serverResponse = 'Server response with Status ' + $scope.serverResponse.status;
            })
        }
    }
})();
