(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .controller('ConnectionAliasController', ConnectionAliasController);

    ConnectionAliasController.$inject = ['$scope', '$state', 'ConnectionAlias', 'ConnectionAliasFilter', 'ParseLinks', 'AlertService'];

    function ConnectionAliasController ($scope, $state, ConnectionAlias, ConnectionAliasFilter, ParseLinks, AlertService) {
        var vm = this;

        vm.connectionAliases = [];
        vm.currentPage = 0;
        vm.pageSize = 20;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        var activeFilter = false;
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;
        vm.clear = clear;
        vm.loadWithFilter = loadWithFilter;

        loadAll(vm.currentPage);

        function loadAll (page) {
            if (activeFilter) {
                ConnectionAliasFilter.query({
                    name: $scope.vm.connectionAlias.name,
                    connectionType: $scope.vm.connectionAlias.connectionType,
                    protocol: $scope.vm.connectionAlias.protocol,
                    host: $scope.vm.connectionAlias.host,
                    port: $scope.vm.connectionAlias.port,
                    path: $scope.vm.connectionAlias.path,
                    authentication: $scope.vm.connectionAlias.authentication,
                    emailRecipients: $scope.vm.connectionAlias.emailRecipients,
                    page: page-1,
                    size: vm.pageSize,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                ConnectionAlias.query({
                    page: page-1,
                    size: vm.pageSize,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.connectionAliases.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.connectionAliases = [];
            loadAll(vm.page);
        }

        function clear () {
            vm.connectionAliases = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = null;
            vm.currentSearch = null;
            vm.loadAll(vm.page);
        }

        function loadWithFilter() {
            console.log("Hello");
            activeFilter = true;
            vm.connectionAliases = [];
            vm.links = null;
            vm.predicate = 'id';
            vm.page = 0;
            vm.reverse = true;
            loadAll(vm.page)
        }
        $scope.changePage = function () {
            vm.connectionAliases = [];
            loadAll(vm.currentPage);
        };
    }
})();
