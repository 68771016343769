(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .controller('MessageController', MessageController)
        .filter('startFrom', function() {
                return function(input, start) {
                        start = +start; //parse to int
                        return input.slice(start);
                    }
        });

    MessageController.$inject = ['$scope', '$state', 'Message', 'MessageSearch', 'MessageFilter', 'ParseLinks', 'AlertService', '$filter'];

    function MessageController ($scope, $state, Message, MessageSearch, MessageFilter, ParseLinks, AlertService) {

        $scope.IsVisible = false;
        $scope.ShowHide = function () {
            //If DIV is visible it will be hidden and vice versa.
            $scope.IsVisible = $scope.IsVisible ? false : true;
        };

        var vm = this;
        var d = new Date();
        var curr_date = ("0" + d.getDate()).slice(-2);
        var curr_month = ("0" + (d.getMonth() + 1)).slice(-2)
        var curr_year = d.getFullYear();
        var activeFilter = false;

        $scope.dateToday = curr_year + "-" + curr_month + "-" + curr_date;
        $scope.dateThisMonth = curr_year + "-" + curr_month;
        $scope.dateThisYear = curr_year;
        $scope.dateRange = "";

        $scope.eventDateFilter = function(column) {
            if(column === 'today') {
                $scope.dateRange = $scope.dateToday;
                console.log($scope.dateToday);
            } else if (column === 'thisMonth') {
                $scope.dateRange = $scope.dateThisMonth;
                console.log($scope.dateThisMonth);
            } else if (column === 'thisYear') {
                $scope.dateRange = $scope.dateThisYear;
                console.log($scope.dateThisYear);
            } else {
                $scope.dateRange = "";
            }
        };

        vm.messages = [];
        vm.currentPage = 0;
        vm.pageSize = 20;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'received';
        vm.reset = reset;
        vm.reverse = false;
        vm.clear = clear;
        vm.search = search;
        vm.loadWithFilter = loadWithFilter;

        loadAll(vm.currentPage);

        function loadAll (page) {
            if (vm.currentSearch) {
                MessageSearch.query({
                    query: vm.currentSearch,
                    page: page -1,
                    size: vm.pageSize,
                    sort: sort()
                }, onSuccess, onError);
            } else if (activeFilter) {
                MessageFilter.query({
                    receiver: $scope.vm.message.receiver,
                    messageId: $scope.vm.message.messageId,
                    referenceNumber:  $scope.vm.message.referenceNumber,
                    sender: $scope.vm.message.sender,
                    type: $scope.vm.message.type,
                    status: $scope.vm.message.status,
                    received: $scope.vm.message.received,
                    range: $scope.vm.message.range,
                    page: page -1,
                    size: vm.pageSize,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Message.query({
                    page: page -1,
                    size: vm.pageSize,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'received') {
                    result.push('received');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.numberOfPages = headers('X-totalPages');
                for (var i = 0; i < data.length; i++) {
                    vm.messages.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.messages = [];
            loadAll(vm.page);
        }

        function clear () {
            vm.messages = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'received';
            vm.reverse = false;
            vm.searchQuery = null;
            vm.currentSearch = null;
            loadAll(vm.page);
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            activeFilter = false;
            vm.messages = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'received';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            loadAll(vm.page);
        }
            function loadWithFilter() {
            activeFilter = true;
                vm.messages = [];
                vm.links = null;
                vm.page = 0;
                vm.reverse = false;
                loadAll(vm.page)
            }

        $scope.changePage = function() {
            vm.messages = [];
            loadAll(vm.currentPage);
        };
    }
})();
