(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .service('dataTableColumnConfigHelper', DataTableColumnConfigHelper);

    DataTableColumnConfigHelper.$inject = ['_'];

    function DataTableColumnConfigHelper (_) {

            this.getHtmlFilter = function(key) {
                return [{
                    condition: function(searchTerm, cellValue) {
                        searchTerm = _.toString(searchTerm).toLowerCase();
                        cellValue = _.toString(cellValue[key]).toLowerCase();

                        // filter case insensitive and split by whitespace if searchTerm is not in quotes
                        var searchTerms = [];

                        // if searchTerm is in quotes, search only for complete term (without quotes)
                        // otherwise split by ' '
                        if (searchTerm.match(/^((".*")|('.*'))$/)) {
                            searchTerms.push(searchTerm.substr(1, searchTerm.length - 2));
                        } else {
                            searchTerms = searchTerms.concat(searchTerm.split(' '));
                        }

                        // return true if all searchTerms are found
                        return _.reduce(searchTerms, function(result, term) {
                            if (!result) {
                                return false;
                            } else {
                                return _.includes(cellValue, term);
                            }
                        }, true);
                    }
                }]
            };

            this.getFilterHeaderTemplate = function() {
                return '<input type="text" class="form-control" ng-model="col.filters[0].term" ng-model-options="{ debounce: 1000 }" />'
            }
    }
})();
