(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .controller('CommunityDetailController', CommunityDetailController);

    CommunityDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Community'];

    function CommunityDetailController($scope, $rootScope, $stateParams, previousState, entity, Community) {
        var vm = this;

        vm.community = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('admintoolApp:communityUpdate', function(event, result) {
            vm.community = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
