(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .factory('CommunityDataTableColumns', CommunityDataTableColumns);

    CommunityDataTableColumns.$inject = [];

    function CommunityDataTableColumns () {

        const columnDefinition = {

            getDefinition: function() {

                return [
                    {
                        name: 'name',
                        translationId: 'admintoolApp.community.name',
                        type: 'TEXT'
                    },
                    {
                        name: 'archiveDays',
                        translationId: 'admintoolApp.community.archiveDays',
                        type: 'NUMBER',
                        width: 190,
                        visible: true
                    },
                    {
                        name: 'deleteDays',
                        translationId: 'admintoolApp.community.deleteDays',
                        type: 'NUMBER',
                        width: 170,
                        visible: true
                    },
                    {
                        name: 'actions',
                        translationId: 'entity.action.actions',
                        type: 'HTML',
                        width: 130,
                        template: '<div class="actions" ng-if="row.entity">' +
                            '<div class="btn-group flex-btn-group-container">' +
                                '<button type="submit" title="{{ \'entity.action.delete\' | translate }}" ui-sref="community.delete({id:row.entity.id})" class="btn btn-danger">' +
                                    '<span class="glyphicon glyphicon-remove-circle"></span>' +
                                '</button>' +
                                '<button type="submit" title="{{ \'entity.action.edit\' | translate }}" ui-sref="community.edit({id:row.entity.id})" class="btn btn-primary">' +
                                    '<span class="glyphicon glyphicon-pencil"></span>' +
                                '</button>' +
                                '<button type="submit" title="{{ \'entity.action.view\' | translate }}" ui-sref="community-detail({id:row.entity.id})" class="btn btn-info">' +
                                    '<span class="glyphicon glyphicon-eye-open"></span>' +
                                '</button>' +
                            '</div>' +
                        '</div>'
                    }
                ];
            }
        };

        return columnDefinition;
    }
})();
