(function() {
	'use strict';

	angular.module('admintoolApp').controller('MessageDetailController',
			MessageDetailController);

	MessageDetailController.$inject = [ '$scope', '$rootScope', '$stateParams',
			'previousState', 'entity', 'entityHeaders', 'entityContent', 'convertedContent',
			'Message', 'Partner' ];

	function MessageDetailController($scope, $rootScope, $stateParams,
			previousState, entity, entityHeaders, entityContent, convertedContent, Message, Partner) {
		var vm = this;

		vm.message = entity;
		vm.messageHeaders = entityHeaders;
		vm.messageContent = entityContent;
        vm.convertedContent = convertedContent;
		vm.previousState = previousState.name;
        console.log(convertedContent, convertedContent.content)

		var unsubscribe = $rootScope.$on('admintoolApp:messageUpdate',
				function(event, result) {
					vm.message = result;
				});
		$scope.$on('$destroy', unsubscribe);
	}
})();
