(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .controller('ConnectionAliasDialogController', ConnectionAliasDialogController);

    ConnectionAliasDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ConnectionAlias', 'Rule'];

    function ConnectionAliasDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ConnectionAlias, Rule) {
        var vm = this;
        vm.connectionAlias = entity;
        vm.clear = clear;
        vm.save = save;

        vm.connectionAlias = entity;
        vm.rulesList = null;
        Rule.get(function (result) {
            vm.rulesList = result;
        }, function (error) {
            vm.rulesList = null;
        });

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.connectionAlias.id !== null) {
                ConnectionAlias.update(vm.connectionAlias, onSaveSuccess, onSaveError);
            } else {
                ConnectionAlias.save(vm.connectionAlias, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('admintoolApp:connectionAliasUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
