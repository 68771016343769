(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .factory('PartnerFilter', PartnerFilter);

    PartnerFilter.$inject = ['$resource'];


    function PartnerFilter($resource, name, designation, status, community, alias) {
        var resourceUrl = 'api/_filter/partners';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                params: {name: name, designation: designation, status: status,
                    community: community, alias: alias},
                isArray: true
            }
        });
    }
})();
