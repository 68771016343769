(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .controller('PartnerIdDialogController', PartnerIdDialogController);

    PartnerIdDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PartnerId', 'Partner'];

    function PartnerIdDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PartnerId, Partner) {
        var vm = this;

        vm.partnerId = entity;
        vm.clear = clear;
        vm.save = save;
        vm.partners = Partner.query({
            size: 1000,
            sort: 'name'
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.partnerId.id !== null) {
                PartnerId.update(vm.partnerId, onSaveSuccess, onSaveError);
            } else {
                PartnerId.save(vm.partnerId, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('admintoolApp:partnerIdUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
