(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .controller('MessageContentDetailController', MessageContentDetailController);

    MessageContentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'MessageContent'];

    function MessageContentDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, MessageContent) {
        var vm = this;

        vm.messageContent = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('admintoolApp:messageContentUpdate', function(event, result) {
            vm.messageContent = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
