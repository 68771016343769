(function() {
    'use strict';
    angular
        .module('admintoolApp')
        .factory('Message', Message);

    Message.$inject = ['$resource', 'DateUtils', '$sce'];

    function Message ($resource, DateUtils, $sce) {
        var resourceUrl =  'api/messages/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.received = DateUtils.convertDateTimeFromServer(data.received);
                        data.delivered = DateUtils.convertDateTimeFromServer(data.delivered);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'resend': {
            	method:'POST',
            	url: 'api/messages/:id/resend',
            	params: {
            		id: '@id'
            	}
            },
            'reprocess' : {
            	method:'POST',
            	url: 'api/messages/:id/reprocess',
             	params: {
            		id: '@id'
            	}
            },
            'headers' : {
            	methos: 'GET',
            	url: 'api/messages/:id/headers',
             	params: {
            		id: '@id'
            	}
            },
            'originalContent' : {
            	methos: 'GET',
            	responseType: 'arraybuffer',
            	cache: true,
            	url: 'api/messages/:id/content/original',
             	params: {
            		id: '@id'
            	},
            	transformResponse: function (data) {
                    var file;
                    var fileURL;
                    if (data) {
                    	file = new Blob([data], {
                            type: 'application/xml'
                        });
                    	fileURL = URL.createObjectURL(file);
                    }
                    return {
                    	file: file,
                    	content: $sce.trustAsResourceUrl(fileURL)
                    };
                }
            },
            'convertedContent' : {
                methos: 'GET',
                responseType: 'arraybuffer',
                cache: true,
                url: 'api/messages/:id/content/converted',
                params: {
                    id: '@id'
                },
                transformResponse: function (data) {
                    console.log(data, data.byteLength);
                    if (data.byteLength === 0) {
                        return null;
                    }
                    var file;
                    var fileURL;
                    if (data) {
                        file = new Blob([data], {
                            type: 'application/xml'
                        });
                        fileURL = URL.createObjectURL(file);
                    }
                    return {
                        file: file,
                        content: $sce.trustAsResourceUrl(fileURL)
                    };
                }
            }
        });
    }
})();
