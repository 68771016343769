(function () {
    'use strict';

    angular.module('admintoolApp')
        .factory('FileUploadService', FileUploadService);

    FileUploadService.$inject = ['$http', '$q', '$location'];

    function FileUploadService($http, $q, $location) {
        return {
            uploadFileToUrl: uploadFileToUrl
        };

        function uploadFileToUrl(file) {
            var uploadUrl = getURL();
            var success = false;
            var fileFormData = new FormData();
            fileFormData.append('file', file);
            var deffered = $q.defer();

            $http.post(uploadUrl, fileFormData, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}

            }).success(function (response) {
                success = true;
                deffered.resolve(response);

            }).error(function (response) {
                success = false;
                deffered.reject(response);
            });
            return deffered.promise;
        }
        function getURL() {
            const fileUrl = "api/upload-file";
           if ($location.absUrl().includes("/admin/")) {
                return "/admin/" + fileUrl;
            } else {
                return fileUrl;
            }
        }
    }
})();
