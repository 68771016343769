(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .factory('PartnerDataTableColumns', PartnerDataTableColumns);

    PartnerDataTableColumns.$inject = ['dataTableColumnConfigHelper'];

    function PartnerDataTableColumns (dataTableColumnConfigHelper) {

        const columnDefinition = {

            getDefinition: function() {

                return [
                    {
                        name: 'name',
                        translationId: 'admintoolApp.partner.name',
                        type: 'TEXT'

                    },
                    {
                        name: 'description',
                        translationId: 'admintoolApp.partner.description',
                        type: 'TEXT',
                        visible: false

                    },
                    {
                        name: 'active',
                        translationId: 'admintoolApp.partner.active',
                        type: 'HTML',
                        width: 80,
                        template: '<span class="label label-danger ng-hide" ng-show="!row.entity.active" style="cursor: pointer" aria-hidden="true">Deactivated</span>' +
                        '<span class="label label-success" ng-show="row.entity.active" style="cursor: pointer" aria-hidden="false">Activated</span>'
                    },
                    {
                        name: 'community',
                        translationId: 'admintoolApp.partner.community',
                        type: 'HTML',
                        enableFiltering: true,
                        filters: dataTableColumnConfigHelper.getHtmlFilter('name'),
                        filterHeaderTemplate: dataTableColumnConfigHelper.getFilterHeaderTemplate,
                        template: '<a ui-sref="community-detail({id:row.entity.community.id})">{{row.entity.community.name}}</a>'
                    },
                    {
                        name: 'connectionAlias',
                        translationId: 'admintoolApp.partner.connectionAlias',
                        type: 'HTML',
                        enableFiltering: true,
                        filters: dataTableColumnConfigHelper.getHtmlFilter('name'),
                        filterHeaderTemplate: dataTableColumnConfigHelper.getFilterHeaderTemplate(),
                        template: '<a ui-sref="connection-alias-detail({id:row.entity.connectionAlias.id})">{{row.entity.connectionAlias.name}}</a>'
                    },
                    {
                        name: 'actions',
                        translationId: 'entity.action.actions',
                        type: 'HTML',
                        width: 130,
                        template: '<div class="actions" ng-if="row.entity">' +
                            '<div class="btn-group flex-btn-group-container">' +
                                '<button type="submit" title="{{ \'entity.action.delete\' | translate }}" ui-sref="partner.delete({id:row.entity.id})" class="btn btn-danger">' +
                                    '<span class="glyphicon glyphicon-remove-circle"></span>' +
                                '</button>' +
                                '<button type="submit" title="{{ \'entity.action.edit\' | translate }}" ui-sref="partner.edit({id:row.entity.id})" class="btn btn-primary">' +
                                    '<span class="glyphicon glyphicon-pencil"></span>' +
                                '</button>' +
                                '<button type="submit" title="{{ \'entity.action.view\' | translate }}" ui-sref="partner-detail({id:row.entity.id})" class="btn btn-info">' +
                                    '<span class="glyphicon glyphicon-eye-open"></span>' +
                                '</button>' +
                            '</div>' +
                        '</div>'
                    }
                ];
            }
        };

        return columnDefinition;
    }
})();
