(function() {
    'use strict';

    angular
        .module('admintoolApp')
        .controller('PartnerDialogController', PartnerDialogController);

    PartnerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Partner', 'PartnerId', 'Community', 'ConnectionAlias'];

    function PartnerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Partner, PartnerId, Community, ConnectionAlias) {
        var vm = this;

        vm.partner = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addAlias = addAlias;
        vm.removeAlias = removeAlias;
        vm.partnerids = PartnerId.query();
		vm.communities = Community.query({
			sort : 'name',
            size : 500
		});
		vm.connectionaliases = ConnectionAlias.query({
            sort : 'name',
            size : 500
		});

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function addAlias(partner) {
            if (!partner.documentConnectionAliases) {
                partner.documentConnectionAliases = [];
            }
            partner.documentConnectionAliases.push({
               type: null,
               connectionAlias: null
            });
        }

        function removeAlias(partner, documentConnectionAlias) {
            const idx = partner.documentConnectionAliases.indexOf(documentConnectionAlias);
            if (idx === -1) {
                return;
            }
            partner.documentConnectionAliases.splice(idx, 1);
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.partner.id !== null) {
                Partner.update(vm.partner, onSaveSuccess, onSaveError);
            } else {
                Partner.save(vm.partner, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('admintoolApp:partnerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
